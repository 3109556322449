import { Component, Link, Redirect } from '@mna/react';

import I18nContext from '../I18nContext';
import authentication from '../lib/msal-b2c-react';
import flogo from '../assets/images/footer_logo.png';
import mainmenu from '../assets/images/smainmenu.gif';

var localStorage = require('web-storage')().localStorage;
var sessionStorage = require('sessionstorage');
var isLoading = false;
export default class Index extends Component {
  constructor(props) {
    super(props);

    var currentLang = sessionStorage.getItem('language');
    if (currentLang === null) {
      currentLang = 'en';
    }

    this.state = {
      loginPage: false,
      isloading: true,
    };

    this.handleCheck = this.handleCheck.bind(this);
  }

  handleCheck(e) {
    localStorage.set('isLoggedIn', 'true');
    sessionStorage.setItem('Usertype', localStorage.get('user_name'));
    sessionStorage.setItem('isClickedLogin', '1');
    authentication.run(() => {
      ReactDOM.render(<Index />, document.getElementById('root'));
    });
  }
  componentWillMount() {
    this.timeout = setTimeout(() => {
      this.setState({
        isloading: false,
      });
    }, 2000);
    isLoading = sessionStorage.getItem('isClickedLogin');
  }

  render() {
    return (
      <I18nContext.Consumer>
        {i18n => (
          <div className="article-width">
            {this.state.isloading ? (
              <div>
                <div className="spacer2" />
                <div className="spacer2" />
                <div className="spacer2" />
                <p className="pointer_style_text">
                  {i18n.t.Loader.searchLoader}
                </p>
                <div className="pointer_style spinner">
                  <div className="bounce1" />
                  <div className="bounce2" />
                  <div className="bounce3" />
                </div>
              </div>
            ) : (
              <div className="site-container">
                {localStorage.get('sessionLogout') ? (
                  <div className="session-notes">Session Expired</div>
                ) : localStorage.get('sessionInvalid') ? (
                  <div className="session-notes">
                    You are not authorized to use this website
                  </div>
                ) : null}
                <main className="page d_board cc">
                  <div className="container">
                    <div className="dashboard home">
                      <div className="home-image">
                        <img src={mainmenu} alt="loginpage" />
                      </div>
                      <div className="login-content text-center pdtop30">
                        <div className="small-text p-20">Welcome</div>
                        <h1 className="p-10">
                          {i18n.t.Dashboard.loginToYourAccount}
                        </h1>
                        <div className="p-20">
                          <button
                            type="button"
                            id="btnLogin"
                            className="login-button"
                            title="Login"
                            onClick={this.handleCheck}
                          >
                            {i18n.t.Dashboard.login}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
                <footer className="page-footer font-small">
                  <div className="container-fluid text-center text-md-left col-md-12 text-center pb-20">
                    <div className="row">
                      <div className="p-20 full-width" />
                      <div className="footer-copyright text-center col-md-12">
                        © 2020 Copyright:
                        <Link to={'/'}>
                          <img src={flogo} alt="mosaique" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            )}
          </div>
        )}
      </I18nContext.Consumer>
    );
  }
}
